import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/usuario',
    component: DefaultLayout,
    children: [
      {
        path: 'permissao/rotina',
        component: () => import('@/views/usuario/permissao/RotinaView.vue')
      }
    ]
  }
]
