import moment from 'moment'
import swal from 'sweetalert'
import _ from 'lodash'
import service from 'mk-auth/src/services/axios.service'

export default {
  methods: {
    currentFormat (value, minimumFractionDigits = 2, lang = 'pt-BR', style = null, currency = null) {
      const options = {}
      if (value === null || value === '') {
        value = 0
      }
      try {
        value = parseFloat(value)
      } catch (error) {
        console.log(error)
      }
      if (typeof value !== 'number') {
        console.log('no number', value)
        return value
      }
      if (currency !== null) {
        options.currency = currency
      }
      if (style !== null) {
        options.style = style
      }
      if (currency !== null) {
        options.currency = currency
      }
      if (minimumFractionDigits !== null) {
        options.minimumFractionDigits = minimumFractionDigits
        options.maximumFractionDigits = minimumFractionDigits
      }
      const formatter = new Intl.NumberFormat(lang, options)
      return formatter.format(value)
    },

    setTitle () {
      let title = 'Result by Criativa'
      const loggedIn = localStorage.getItem('user')
      if (loggedIn) {
        const user = JSON.parse(loggedIn)
        title = `${user.company.toUpperCase()} - ${title}`
      }
      document.title = title
    },

    dateFormat (data, f = 'DD/MM/YYYY') {
      return data === null || data === '' ? '' : moment(data).format(f)
    },

    dateNow () {
      return moment().format('YYYY-MM-DD hh:mm:ss')
    },

    dialogAlert ({ title = '', message = '' }, type = 'success') {
      swal(title, message, type)
    },

    confirm ({ title, message }, callback) {
      swal({
        title: title,
        text: message,
        icon: 'warning',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim'
        },
        backdrop: 'rgba(60,60,60,0.8)',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#c03221'
      }).then((confirm) => {
        if (confirm) {
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    },

    async findReceita (value) {
      console.log(value)
      const obj = await service.get(`criativa/helper/consulta/pessoa/${value}`).then(response => {
        if (response.data.ibge) {
          response.data.cidade = response.data.ibge.cidade
          response.data.uf = response.data.ibge.uf
          response.data.ibge = response.data.ibge.ibge
        }
        response.data.tipo = response.data.cnpj && response.data.cnpj !== '' ? 'J' : 'F'
        return Promise.resolve(response.data)
      })
      return obj
    },

    async findCep (value) {
      console.log(value)
      const obj = await service.get(`criativa/helper/consulta/cep/${value}`).then(response => {
        response.data.cidade = response.data.municipio
        return Promise.resolve(response.data)
      })
      return obj
    },

    async checkPermissao (id, redirect = false) {
      const response = await service.checkPermission(id)
      if (!response && redirect) {
        this.$router.push('/no-access')
      }
      return response
    },

    crypto (value) {
      return window.btoa(value)
    },

    decrypto (value) {
      return window.atob(value)
    },

    expandedSidenav () {
      const html = document.getElementsByTagName('html')[0]

      if (!html.classList.contains('layout-expanded')) {
        html.classList.add('layout-expanded')
      }
    },

    toggleSidenav () {
      // const collapsed = typeof window.layoutHelpers.collapsed === 'undefined' ? false : window.layoutHelpers.collapsed
      // window.layoutHelpers.collapsed = !collapsed

      const html = document.getElementsByTagName('html')[0]

      if (html.classList.contains('layout-expanded')) {
        html.classList.remove('layout-expanded')
      } else if (!html.classList.contains('layout-collapsed')) {
        html.classList.add('layout-collapsed')
      } else {
        html.classList.remove('layout-collapsed')
      }
    },

    sideNavHover () {
      const html = document.getElementsByTagName('html')[0]

      // console.log('toggle hover')

      if (html.classList.contains('layout-collapsed')) {
        if (!html.classList.contains('layout-sidenav-hover')) {
          html.classList.add('layout-sidenav-hover')
        }
      }
    },

    sideNavLeave () {
      const html = document.getElementsByTagName('html')[0]

      // console.log('toggle leave')

      if (html.classList.contains('layout-collapsed')) {
        if (html.classList.contains('layout-sidenav-hover')) {
          html.classList.remove('layout-sidenav-hover')
        }
      }
    },

    filterArray (array, filters) {
      const filterKeys = Object.keys(filters)
      return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
          // ignores non-function predicates
          if (typeof filters[key] !== 'function') return true
          return filters[key](item[key])
        })
      })
    }
  }
}
