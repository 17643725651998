import AxiosService from 'mk-auth/src/services/axios.service'

export default {
  pedido ({ commit }, payload) {
    return AxiosService.get(payload).then(
      response => {
        response.data.item.forEach(element => {
          element.qcom = parseFloat(element.qcom)
          element.vuncom = parseFloat(element.vuncom)
          element.vdesc = parseFloat(element.vdesc)
          element.vprod = parseFloat(element.vprod)
          element.vfrete = parseFloat(element.vfrete)
          element.vseg = parseFloat(element.vseg)
          element.voutro = parseFloat(element.voutro)
          element.predbc = parseFloat(element.predbc)
          element.vbcicms = parseFloat(element.vbcicms)
          element.picms = parseFloat(element.picms)
          element.vicms = parseFloat(element.vicms)
          element.vbcfcp = parseFloat(element.vbcfcp)
          element.pfcp = parseFloat(element.pfcp)
          element.vfcp = parseFloat(element.vfcp)
          element.vicmsdeson = parseFloat(element.vicmsdeson)
          element.pbcop = parseFloat(element.pbcop)
          element.vicmsop = parseFloat(element.vicmsop)
          element.predbcicmsst = parseFloat(element.predbcicmsst)
          element.pmvast = parseFloat(element.pmvast)
          element.vbcicmsst = parseFloat(element.vbcicmsst)
          element.picmsst = parseFloat(element.picmsst)
          element.vicmsst = parseFloat(element.vicmsst)
          element.vbcfcpst = parseFloat(element.vbcfcpst)
          element.pfcpst = parseFloat(element.pfcpst)
          element.vfcpst = parseFloat(element.vfcpst)
          element.vicmssubstituto = parseFloat(element.vicmssubstituto)
          element.vicmsstdeson = parseFloat(element.vicmsstdeson)
          element.vbcstreticms = parseFloat(element.vbcstreticms)
          element.vicmsstret = parseFloat(element.vicmsstret)
          element.vbcicmsstdest = parseFloat(element.vbcicmsstdest)
          element.vicmsstdest = parseFloat(element.vicmsstdest)
          element.vbcfcpstret = parseFloat(element.vbcfcpstret)
          element.pfcpstret = parseFloat(element.pfcpstret)
          element.vfcpstret = parseFloat(element.vfcpstret)
          element.picmsdif = parseFloat(element.picmsdif)
          element.vicmsdif = parseFloat(element.vicmsdif)
          element.pfcpdif = parseFloat(element.pfcpdif)
          element.vfcpdif = parseFloat(element.vfcpdif)
          element.predbcefet = parseFloat(element.predbcefet)
          element.vbcefet = parseFloat(element.vbcefet)
          element.picmsefet = parseFloat(element.picmsefet)
          element.vicmsefet = parseFloat(element.vicmsefet)
          element.vfcpefet = parseFloat(element.vfcpefet)
          element.pcredicmssn = parseFloat(element.pcredicmssn)
          element.vcredicmssn = parseFloat(element.vcredicmssn)
          element.qseloipi = parseFloat(element.qseloipi)
          element.vbcipi = parseFloat(element.vbcipi)
          element.pipi = parseFloat(element.pipi)
          element.vipi = parseFloat(element.vipi)
          element.qunidipi = parseFloat(element.qunidipi)
          element.vunidipi = parseFloat(element.vunidipi)
          element.pdevol = parseFloat(element.pdevol)
          element.vipidevol = parseFloat(element.vipidevol)
          element.vbcpis = parseFloat(element.vbcpis)
          element.ppis = parseFloat(element.ppis)
          element.vpis = parseFloat(element.vpis)
          element.qbcprodpis = parseFloat(element.qbcprodpis)
          element.valiqprodpis = parseFloat(element.valiqprodpis)
          element.vbcpisst = parseFloat(element.vbcpisst)
          element.ppisst = parseFloat(element.ppisst)
          element.vpisst = parseFloat(element.vpisst)
          element.qbcprodpisst = parseFloat(element.qbcprodpisst)
          element.valiqprodpisst = parseFloat(element.valiqprodpisst)
          element.vbccofins = parseFloat(element.vbccofins)
          element.pcofins = parseFloat(element.pcofins)
          element.vcofins = parseFloat(element.vcofins)
          element.qbcprodcofins = parseFloat(element.qbcprodcofins)
          element.valiqprodcofins = parseFloat(element.valiqprodcofins)
          element.vbccofinsst = parseFloat(element.vbccofinsst)
          element.pcofinsst = parseFloat(element.pcofinsst)
          element.vcofinsst = parseFloat(element.vcofinsst)
          element.qbcprodcofinsst = parseFloat(element.qbcprodcofinsst)
          element.valiqprodcofinsst = parseFloat(element.valiqprodcofinsst)
          element.vbcufdest = parseFloat(element.vbcufdest)
          element.picmsufdest = parseFloat(element.picmsufdest)
          element.picmsinter = parseFloat(element.picmsinter)
          element.picmsinterpart = parseFloat(element.picmsinterpart)
          element.vicmsufremet = parseFloat(element.vicmsufremet)
          element.vicmsufdest = parseFloat(element.vicmsufdest)
          element.vbcfcpufdest = parseFloat(element.vbcfcpufdest)
          element.pfcpufdest = parseFloat(element.pfcpufdest)
          element.vfcpufdest = parseFloat(element.vfcpufdest)
        })
        commit('setPedido', response.data)
        return Promise.resolve(response.data)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },

  save ({ commit }, params) {
    return AxiosService.post(params.uri, params.object).then(
      resp => {
        commit('setPedido', params)
        return Promise.resolve(resp.data)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },

  item ({ commit }, params) {
    commit('setItem', params)
  }
}
