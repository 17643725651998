<template>
  <div id="layout-sidenav" class="layout-sidenav sidenav sidenav-vertical bg-dark" @mouseover="sideNavHover" @mouseleave="sideNavLeave">
    <div class="app-brand">
      <span class="app-brand-logo">
        <img src="@/assets/ico.png" class="img-responsive" height="40">
      </span>
      <a href="./" class="app-brand-text demo sidenav-text font-weight-normal ml-2">
        Result
        <small style="display: block; font-size: 7pt; margin-left: 20px">by CRIATIVA</small>
      </a>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="fa fa-align-justify"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0"></div>
    <div class="sidenav-block">
      <div class="form-group">
        <input type="text" class="form-control form-control-inverted" v-model="inputFindRoutine" placeholder="Pesquise por rotina" autocomplete="off">
      </div>
    </div>
    <div class="sidenav-divider mt-0"></div>

    <!-- Links -->
    <div class="sidenav-header small font-weight-semibold">ROTINAS</div>
    <ul class="sidenav-inner py-1" id="list-rotina">

      <SidenavItem :itens="routines" :find="inputFindRoutine"/>

    </ul>

    <div class="sidenav-divider mb-0"></div>

    <div class="sidenav-block my-1">
      <div class="small" v-for="(v, i) in serverInfos" :key="i">
        {{ v.label }}
        <div class="float-right">{{ v.info }}</div>
      </div>
    </div>

    <br>

    <div class="sidenav-divider mb-0"></div>
  </div>
</template>

<script>
// import axios from 'axios'
// import config from '@/config'
// import authHeader from 'mk-auth/src/services/auth-header'
import SidenavItem from '../custom/sidebar/SidenavItem.vue'

import service from 'mk-auth/src/services/axios.service'
// import service from '@/axios.service'

const SideNav = require('@/vendor/libs/sidenav/sidenav.js').SideNav

export default {
  components: {
    SidenavItem
  },
  data () {
    return {
      inputFindRoutine: '',
      serverInfos: [
        { label: 'Memoria', info: '256MB' }
      ]
    }
  },
  methods: {
    filtered (obj, matching) {
      console.log(obj.sub.filter(sub1 => sub1.nome.toUpperCase().match(matching)).length > 0)
      if (obj.dir !== '' && obj.nome.toUpperCase().match(matching)) {
        return true
      } else if (obj.sub.filter(sub1 => sub1.nome.toUpperCase().match(matching)).length > 0) {
        return true
      } else if (obj.sub.filter(sub1 => sub1.filter(sub2 => sub2.nome.toUpperCase().match(matching)).length > 0)) {
        return true
      } else {
        return false
      }
    }
  },
  props: {
    orientation: {
      type: String,
      default: 'vertical'
    },
    animate: {
      type: Boolean,
      default: true
    },
    accordion: {
      type: Boolean,
      default: true
    },
    closeChildren: {
      type: Boolean,
      default: false
    },
    showDropdownOnHover: {
      type: Boolean,
      default: false
    },
    onOpen: Function,
    onOpened: Function,
    onClose: Function,
    onClosed: Function
  },
  async mounted () {
    this.SideNav = new SideNav(this.$el, {
      orientation: this.orientation,
      animate: this.animate,
      accordion: this.accordion,
      closeChildren: this.closeChildren,
      showDropdownOnHover: this.showDropdownOnHover,

      onOpen: this.onOpen,
      onOpened: this.onOpened,
      onClose: this.onClose,
      onClosed: this.onClosed
    }, null)
  },
  async setup () {
    const response = await service.get(`criativa/user/routine/getlist/front/${process.env.NODE_ENV}`)
    const routines = response.data ? response.data : []
    return { routines }
  }
}
</script>
