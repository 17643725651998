<template>
  <div v-show="visibled">
    <div class="blockUI" style="display:none"></div>
    <div class="blockUI blockOverlay" style="z-index: 1000; border: none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; background-color: rgb(255, 255, 255); opacity: 0.8; cursor: wait; position: absolute;"></div>
    <div class="blockUI blockMsg blockElement" style="z-index: 1011; position: absolute; padding: 0px; margin: 0px; width: 30%; top: calc(50% - 30px); left: calc(50% - 18%); text-align: center; color: rgb(0, 0, 0); border: 0px; background-color: transparent; cursor: wait;">
      <div class="sk-wave sk-primary mx-auto">
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
      </div>
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String
    }
  },
  computed: {
    visibled () {
      return this.message !== ''
    }
  }
}
</script>
