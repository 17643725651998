import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/cms',
    component: DefaultLayout,
    meta: { controller: 'CRM' },
    children: [
      {
        path: 'carousel',
        component: () => import('@/views/cms/carousel/CarouselView.vue'),
        meta: { action: 'Controle de carousel' }
      }
    ]
  }
]
