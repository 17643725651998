import { createApp } from 'vue'

import BootstrapVue3 from 'bootstrap-vue-3'

import App from './App.vue'

import store from './store'
import router from './router'

import globalComponent from './plugins/global-component'
import globalMixin from './plugins/global-mixin'

const app = createApp(App)
app.use(router).use(store)
app.use(BootstrapVue3).use(globalComponent)
app.mixin(globalMixin)

app.mount('#app')
