import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/chassi',
    component: DefaultLayout,
    meta: { controller: 'Chassi' },
    children: [
      {
        path: '',
        component: () => import('@/views/chassi/ChassiView.vue'),
        meta: { action: 'Controle de Chassi' }
      }
    ]
  }
]
