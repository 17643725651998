import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/compras',
    component: DefaultLayout,
    meta: {
      controller: 'Compras'
    },
    children: [
      {
        path: 'precificacao',
        component: () => import('@/views/compras/precificacao/PrecificacaoView.vue'),
        meta: {
          action: 'Precificação'
        }
      },
      {
        path: 'pedido',
        component: () => import('@/views/compras/pedido/IndexView.vue'),
        meta: {
          action: 'Consultar/Alterar pedido de compra'
        }
      },
      {
        path: 'digitar/:numped',
        component: () => import('@/views/compras/pedido/DigitarView.vue'),
        meta: {
          action: 'Digitar pedido de compra'
        }
      }
    ]
  }
]
