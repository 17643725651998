import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/produto',
    component: DefaultLayout,
    meta: {
      controller: 'Produto'
    },
    children: [
      {
        path: 'tributacao',
        component: () => import('@/views/produto/tributacao/TributacaoView.vue'),
        meta: {
          action: 'Tributação de Saída'
        }
      },
      {
        path: 'tributacao/:codprod',
        component: () => import('@/views/produto/tributacao/TributacaoView.vue'),
        meta: {
          action: 'Tributação de Saída'
        }
      }
    ]
  }
]
