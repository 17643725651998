import { createStore, createLogger } from 'vuex'
import auth from 'mk-auth/src/store'
import form from 'mk-form-component/src/store'

import compra from './compras/pedido'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    auth,
    form,
    compra
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
export default store
