export default {
  setPedido (state, payload) {
    state.pedido = payload
  },
  setItem (state, payload) {
    state.pedido.item = payload
  },
  upItem (state, payload) {
    const item = state.item.filter(obj => obj.codprod === payload.codprod)
    if (payload.prop === 'update' && item.length > 0) {
      item[0] = payload
    } else {
      state.pedido.item.push(payload)
    }
  }
}
