<template>
  <template v-if="dialog === 'N'">
    <template v-if="dir !== ''">
      <router-link :to="{ path: dir }" :props="{ controller:'teste', action: 'tt' }" class="sidenav-link">
        <i class="mr-2" v-if="icon !== ''">
          <font-awesome-icon :icon="icon.split(' ')" class="sidenav-icon"/>
        </i>
        <span class="mr-2" v-else>{{ codrotina }}</span>
        {{ nome }}
      </router-link>
    </template>
    <template v-else>
      <a href="#" class="sidenav-link sidenav-toggle">
        <i class="mr-2" v-if="icon !== ''">
          <font-awesome-icon :icon="icon.split(' ')" class="sidenav-icon"/>
        </i>
        {{ nome }}
      </a>
    </template>
  </template>
  <template v-else>
    <a dir="sub1.dir" class="sidenav-link">{{ codrotina }} {{ nome }}</a>
  </template>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    codrotina: String,
    dir: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    nome: String,
    sub: [String, Array],
    dialog: {
      type: String,
      default: 'N'
    }
  }
}
</script>
