import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/estoque',
    component: DefaultLayout,
    meta: {
      controller: 'Estoque'
    },
    children: [
      {
        path: 'entrada',
        component: () => import('@/views/estoque/entrada/IndexView.vue'),
        meta: {
          controller: 'Recebimento de Mercadoria',
          action: 'Entrada de Mercadoria'
        }
      },
      {
        path: 'entrada/cancelar',
        component: () => import('@/views/estoque/entrada/CancelarView.vue')
      },
      {
        path: 'entrada/:id',
        component: () => import('@/views/estoque/entrada/EntradaView.vue'),
        meta: {
          controller: 'Recebimento de Mercadoria',
          action: 'Entrada de Mercadoria'
        }
      },
      {
        path: 'cliente/devolucao',
        component: () => import('@/views/estoque/cliente/IndexView.vue')
      },
      {
        path: 'cliente/devolucao/cancelar',
        component: () => import('@/views/estoque/cliente/CancelarView.vue')
      },
      {
        path: 'cliente/devolucao/:id',
        component: () => import('@/views/estoque/cliente/DevolucaoView.vue')
      },
      {
        path: 'fornecedor/devolucao',
        component: () => import('@/views/estoque/fornecedor/IndexView.vue')
      },
      {
        path: 'fornecedor/devolucao/cancelar',
        component: () => import('@/views/estoque/fornecedor/CancelarView.vue')
      },
      {
        path: 'fornecedor/devolucao/:id',
        component: () => import('@/views/estoque/fornecedor/DevolucaoView.vue')
      }
    ]
  }
]
