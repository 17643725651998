<template>
<div class="page-loader">
    <div class="bg-primary"></div>
</div>

<!-- Content -->

<div class="authentication-wrapper authentication-3">
    <div class="authentication-inner">

        <!-- Side container -->
        <!-- Do not display the container on extra small, small and medium screens -->
        <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5" style="background-image: url('image/bg/2.jpg');">
            <div class="ui-bg-overlay bg-dark opacity-50"></div>

            <!-- Text -->
            <div class="w-100 text-white px-5">
                <img src="../assets/logo-criativa-invert.png">
                <div class="text-large font-weight-light m">
                    Soluções criativas para sua empresa
                </div>
            </div>
            <!-- /.Text -->

        </div>
        <!-- / Side container -->

        <!-- Form container -->
        <div class="d-flex col-lg-4 align-items-center bg-white p-5">
        <!-- Inner container -->
        <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
        <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
            <div class="w-100">

            <!-- Logo -->
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/logo.png?logo" width="230">
            </div>
            <!-- / Logo -->

            <h4 class="text-center text-lighter font-weight-normal mt-4 mb-0">Entre com sua Conta</h4>

            <router-view/>

            <div class="text-center">
                <small>Criativa 4.0.2 &copy; 2013 - 2022</small>
            </div>

            </div>
        </div>
        </div>
        <!-- / Form container -->

    </div>
</div>
</template>

<style scoped>
@import '@/vendor/style/authentication.css';
</style>

<script>
export default {
  mounted () {
    this.setTitle()
  }
}
</script>
