import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/backup',
    component: DefaultLayout,
    meta: { controller: 'Backup de Segurança' },
    children: [
      {
        path: '',
        component: () => import('@/views/backup/IndexView.vue'),
        meta: { action: 'Monitor de Backup' }
      }
    ]
  }
]
