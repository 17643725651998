import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/dashboard',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/dashboard/DashBoardView.vue')
      }
    ]
  }
]
