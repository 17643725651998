<template>
  <li class="sidenav-item" v-for="item in (find === '' ? itens : itens.filter(x => (x.nome.toUpperCase().match(find.toUpperCase()) && x.dir !== '') || (x.sub.filter(y => (y.nome.toUpperCase().match(find.toUpperCase()) && y.dir !== '') || (typeof y.sub === 'object' && y.sub.filter(z => z.nome.toUpperCase().match(find.toUpperCase())).length > 0)).length > 0)))" :key="item.codrotina">
    <SidenavLink v-bind="item" />
    <template v-if="item.sub.length > 0">
      <ul class="sidenav-menu">
        <li class="sidenav-item" v-for="sub1 in (find === '' ? item.sub : item.sub.filter(x => (x.nome.toUpperCase().match(find.toUpperCase()) && x.dir !== '') || (typeof x.sub === 'object' && x.sub.filter(y => y.nome.toUpperCase().match(find.toUpperCase())).length > 0)))" :key="sub1.codrotina">
          <SidenavLink v-bind="sub1"/>
          <template v-if="sub1.sub.length > 0">
            <ul class="sidenav-menu">
              <li class="sidenav-item" v-for="sub2 in (find === '' ? sub1.sub : sub1.sub.filter(x => x.nome.toUpperCase().match(find.toUpperCase())))" :key="sub2.codrotina">
                <SidenavLink v-bind="sub2"/>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </template>
  </li>
</template>
<script>
import SidenavLink from './SidenavLink.vue'
export default {
  props: {
    itens: Array,
    find: String
  },
  components: {
    SidenavLink
  }
}
</script>
