<template>
  <router-view/>
  <div class="box" ref="container"></div>
</template>

<script>
export default {
  name: 'App',
  created () {
    // this.$store.dispatch('form/set', { codusuario: 1 })
    // console.log(this.$store.state)
  },
  mounted () {
    // console.log(this.get)
  }
}
</script>
