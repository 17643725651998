<template>
  <!-- Layout wrapper -->
  <div class="layout-wrapper layout-2">
    <div class="layout-inner">
      <Suspense>
        <SidenavComponentVue/>
      </Suspense>

      <!-- Layout container -->
      <div class="layout-container">
          <NavBarVue/>

          <!-- Layout content -->
          <div class="layout-content">

              <!-- Content -->
              <div class="container-fluid flex-grow-1 container-p-y">
                <h4 class="font-weight-bold py-3 mb-4" v-if="this.$route.meta.action">
                  {{ this.$route.meta.action }}
                  <div class="text-muted text-tiny mt-1 font-weight-light">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a>Result</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a>{{ this.$route.meta.controller }}</a>
                      </li>
                      <li class="breadcrumb-item active">
                        <strong>{{ this.$route.meta.action }}</strong>
                      </li>
                    </ol>
                  </div>
                </h4>
                <Suspense>
                  <router-view/>
                </Suspense>
              </div>
              <!-- / Content -->

              <!-- Layout footer -->
              <nav class="layout-footer footer bg-footer-theme">
                  <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
                      <div class="pt-3">
                          <span class="footer-text font-weight-bolder">Criativa Soluções</span>
                      </div>
                  </div>
              </nav>
              <!-- / Layout footer -->

          </div>
          <!-- Layout content -->

      </div>
      <!-- / Layout container -->
    </div>
    <div class="layout-overlay layout-sidenav-toggle" @click="toggleSidenav()"></div>
  </div>
</template>

<style scoped>
@import '@/vendor/style/bootstrap.css';
@import '@/vendor/style/appwork.css';
@import '@/vendor/style/colors.css';
@import '@/vendor/style/theme-corporate.css';
@import '@/vendor/style/uikit.css';
@import '@/vendor/libs/spinkit/spinkit.css';
@import '@/assets/style/style.css';

</style>

<script>
import '@/vendor/js/bootstrap.js'
import '@/vendor/js/sidenav.js'

import SidenavComponentVue from '@/components/partials/SidenavComponent.vue'
import NavBarVue from '@/components/NavBar.vue'

export default {
  components: {
    SidenavComponentVue,
    NavBarVue
  },
  mounted () {
    this.setTitle()
  }
}
</script>
