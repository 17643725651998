<template>
  <!-- Layout navbar -->
  <nav class="layout-navbar navbar navbar-expand-lg align-items-lg-center bg-white container-p-x" id="layout-navbar">
    <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="expandedSidenav()">
        <i class="fa fa-align-justify"></i>
      </a>
    </div>

    <a href="./" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">
      <span class="app-brand-logo">
        <img src="@/assets/ico.png" class="img-responsive" height="40">
      </span>
      <span class="app-brand-text demo font-weight-normal ml-2">
        Result
        <small style="display: block; font-size: 7pt; margin-left: 20px">by CRIATIVA</small>
      </span>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#layout-navbar-collapse">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" id="layout-navbar-collapse">
        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2">

        <div class="navbar-nav align-items-lg-center">
            <div class="nav-item mr-lg-3">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Empresa: {{ user.company.toUpperCase() }}</span>
            </div>
        </div>

        <div class="navbar-nav align-items-lg-center ml-auto">
          <div class="nav-item mr-lg-3">
            <a class="nav-link" href="javascript:;" id="fullscreen">
              <i class="ion ion-ios-expand navbar-icon align-middle"></i>
              <span class="d-lg-none align-middle">&nbsp; Fullscreen</span>
            </a>
          </div>

          <div class="nav-item mr-lg-3 d-none">
            <label class="switcher">
              <input type="checkbox" class="switcher-input js-push">
              <span class="switcher-indicator">
                <span class="switcher-yes"></span>
                <span class="switcher-no"></span>
              </span>
              <span class="switcher-label">Notificação</span>
            </label>
          </div>

          <div class="nav-item mr-lg-3 d-none">
            <label class="switcher">
              <input type="checkbox" class="switcher-input js-window">
              <span class="switcher-indicator">
                <span class="switcher-yes"></span>
                <span class="switcher-no"></span>
              </span>
              <span class="switcher-label">Mode Window</span>
            </label>
          </div>

          <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

          <div class="demo-navbar-user nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <div class="d-block ui-w-30 rounded-circle" user-avatar style="height: 30px"></div>
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0" user-nome>{{ user.name }}</span>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a href="#/criativaUser/perfil" class="dropdown-item">
                <i class="ion ion-ios-person text-lightest"></i> &nbsp; Perfil
              </a>
              <div class="dropdown-divider"></div>
              <a @click="logOut" class="dropdown-item">
                <i class="ion ion-ios-log-out text-danger"></i> &nbsp; Fechar Sessão</a>
              </div>
            </div>
        </div>
    </div>
  </nav>
  <!-- / Layout navbar -->
</template>

<script>
export default {
  methods: {
    logOut () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    }
  },
  setup () {
    const user = JSON.parse(localStorage.getItem('user'))
    return { user }
  }
}
</script>
