import { createRouter, createWebHistory } from 'vue-router'
import AuthService from 'mk-auth/src/services/auth.service'
import store from '@/store'

import DefaultLayout from '@/layout/DefaultLayout'
import BlankLayout from '@/layout/BlankLayout'

import formRouter from 'mk-form-component/src/router'
import backupRouter from './backup'
import chassiRouter from './chassi'
import cmsRouter from './cms'
import comprasRouter from './compras'
import crmRouter from './crm'
import dashboardRouter from './dashboard'
import estoqueRouter from './estoque'
import integracaoRouter from './integracao'
import lancamentoRouter from './lancamento'
import prestacaoRouter from './prestacao'
import produtoRouter from './produto'
import usuarioRouter from './usuario'
import versaoRouter from './versao'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/home/IndexView.vue'),
        meta: {
          controller: 'Home',
          action: 'Index'
        }
      }
    ]
  },
  {
    path: '/report/:id/:query',
    component: () => import('@/views/report/ReportView.vue')
  },
  {
    path: '/login',
    component: BlankLayout,
    children: [
      {
        path: '',
        component: () => import('mk-auth/src/LoginView.vue')
      }
    ]
  },
  {
    path: '/no-access',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/NoAccess.vue')
      }
    ]
  },
  {
    path: '/teste',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/TesteView.vue')
      }
    ]
  }
]
  .concat(formRouter)
  .concat(backupRouter)
  .concat(chassiRouter)
  .concat(cmsRouter)
  .concat(comprasRouter)
  .concat(crmRouter)
  .concat(dashboardRouter)
  .concat(estoqueRouter)
  .concat(integracaoRouter)
  .concat(lancamentoRouter)
  .concat(prestacaoRouter)
  .concat(produtoRouter)
  .concat(usuarioRouter)
  .concat(versaoRouter)

routes.push({
  path: '/:pathMatch(.*)*',
  component: () => import('@/views/NotFound.vue')
})

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory('/my/'),
  routes // short for `routes: routes`
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (loggedIn) {
    const json = JSON.parse(loggedIn)
    const statsUser = await AuthService.status({ accessToken: json.accessToken })
    if (!statsUser.status) {
      store.dispatch('auth/logout')
      // localStorage.removeItem('user')
      next('/login')
    }
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
