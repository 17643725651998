import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/lancamento',
    component: DefaultLayout,
    meta: {
      controller: 'Contas a Pagar'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/lancamento/AlterarView.vue'),
        meta: {
          action: 'Consultar/Alterar titulo a pagar'
        }
      },
      {
        path: 'incluir',
        component: () => import('@/views/lancamento/IncluirView.vue'),
        meta: {
          action: 'Incluir titulo a pagar'
        }
      },
      {
        path: 'baixar',
        component: () => import('@/views/lancamento/BaixarView.vue'),
        meta: {
          action: 'Baixar titulo a pagar'
        }
      },
      {
        path: 'estornar',
        component: () => import('@/views/lancamento/EstornarView.vue'),
        meta: {
          action: 'Estornar titulo a pagar'
        }
      }
    ]
  }
]
