import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/prestacao',
    component: DefaultLayout,
    meta: {
      controller: 'Contas a Receber'
    },
    children: [
      {
        path: 'incluir',
        component: () => import('@/views/prestacao/IncluirView.vue'),
        meta: {
          action: 'Incluir titulo a receber'
        }
      },
      {
        path: 'baixar',
        component: () => import('@/views/prestacao/BaixarView.vue'),
        meta: {
          action: 'Baixar titulo a receber'
        }
      },
      {
        path: 'estornar',
        component: () => import('@/views/prestacao/EstornarView.vue'),
        meta: {
          action: 'Estornar titulo a receber'
        }
      },
      {
        path: 'desdobrar',
        component: () => import('@/views/prestacao/DesdobrarView.vue'),
        meta: {
          action: 'Agrupar/Desdobrar titulo a receber'
        }
      },
      {
        path: 'relatorio',
        component: () => import('@/views/prestacao/RelatorioView.vue'),
        meta: {
          action: 'Relatório de titulo a receber'
        }
      }
    ]
  }
]
