import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/integracao/winthor',
    component: DefaultLayout,
    children: [
      {
        path: 'geo',
        component: () => import('@/views/integracao/winthor/GeoView.vue')
      }
    ]
  }
]
