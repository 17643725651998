import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/crm',
    component: DefaultLayout,
    meta: { controller: 'CRM' },
    children: [
      {
        path: 'simulador',
        component: () => import('@/views/crm/SimuladorView.vue'),
        meta: { action: 'Simulador de Atendimento' }
      }
    ]
  }
]
