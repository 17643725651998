import DefaultLayout from '@/layout/DefaultLayout'

export default [
  {
    path: '/versao',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/versao/VersaoView.vue')
      }
    ]
  }
]
